// @ts-check
import concat from "lodash/concat";
import flatten from "lodash/flatten";

import {RESOURCE_HISTORY} from "../../../config/api_config";
import {selectFeSettings} from "../../components/fe_settings/fe_settings_selectors";
import {loadNamesAction} from "../../components/private_data/private_data_actions";
import getPersonIdsFromInfo from "../../components/private_data/utils/get_person_ids_from_info";
import getPersonIdsFromScheduleOps from "../../components/private_data/utils/get_person_ids_from_schedule_ops";
import {authUserFailureAction} from "../../redux/actions/index";
import {selectCurrentUserEmail} from "../../redux/app_selectors";
import STATUS from "../../redux/utils/status";
import {isEmergencyFunc} from "../../utils/is_emergency";
import logger from "../../utils/logger_pino";
import {sliceIntoChunks} from "../../utils/slice_into_chunks";
import verifyAppointments from "../../utils/verify_appointments";
import {selectLastPublishedSession} from "../op_management/op_management_selectors";
import ActionTypes from "./day_view_action_types";
import {fetchChangeHistoryForToday, fetchCustomer, fetchInfo, fetchKi, fetchPrintOnCall, fetchResources} from "./day_view_api";
import {filterChanges, formatChanges} from "./helpers";

/**
 * Selected date for the main view
 * @param {DateTimeType} payload DateTime object
 * @return {{type: string, payload: *}}
 */
function changeDate(payload) {
    return {type: ActionTypes.CHANGE_DATE, payload};
}

/**
 * save op rooms labels
 * @param {string[]} payload array of locationIds
 * @return {{payload: string[], type: string}}
 */
function saveOpRooms(payload) {
    return {type: ActionTypes.SAVE_OP_ROOMS, payload};
}

const loadOpDataRequestAction = () => ({
    type: ActionTypes.LOAD_REQUEST
});

const loadOpDataSuccessAction = (payload, status) => ({
    type: ActionTypes.LOAD_SUCCESS,
    payload,
    status
});

const loadOpDataFailureAction = (error) => ({
    type: ActionTypes.LOAD_FAILURE,
    error
});

/**
 * load op data with ki endpoint
 * @param {string} organizationId
 * @param {string} date
 * @return {AnyAction}
 */
function loadOpDataKiAction(organizationId, date) {
    // @ts-ignore @todo: fix this
    return function (dispatch, getState) {
        dispatch(loadOpDataRequestAction());
        const email = selectCurrentUserEmail(getState());

        fetchKi(organizationId, date, email)
            .then(([{data: real}, {data: ki}]) => {
                const {ok: okKiOps, okOps: kiOps, errOps: errorKiOps} = verifyAppointments(ki.data);
                const {ok: okRealOps, okOps: realOps, errOps: errorRealOps} = verifyAppointments(real.data);

                // send warnings
                if (!okKiOps || !okRealOps) {
                    logger.warn("DayView - AI: error appointments", {
                        organizationId,
                        email,
                        errorKiOps,
                        errorRealOps
                    });
                }
                const allOps = [...kiOps, ...realOps];
                const status = real.ok && ki.ok ? STATUS.RESOLVED : STATUS.REJECTED;
                dispatch(loadOpDataSuccessAction(allOps, status));

                // load names
                const {practitionerIds, patientIds} = getPersonIdsFromScheduleOps(allOps);
                if (practitionerIds && practitionerIds.length) {
                    dispatch(loadNamesAction("practitioner", practitionerIds, false));
                }
                if (patientIds && patientIds.length) {
                    dispatch(loadNamesAction("patient", patientIds, false));
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch ki error"}));
                } else {
                    dispatch(loadOpDataFailureAction(error.message));
                }
            });
    };
}

/**
 * load op data with customer endpoint
 * @param {string} organizationId
 * @param {string} date
 * @return {AnyAction}
 */
function loadOpDataCustomerAction(organizationId, date) {
    // @ts-ignore @todo: fix this
    return function (dispatch, getState) {
        const email = selectCurrentUserEmail(getState());
        dispatch(loadOpDataRequestAction());

        fetchCustomer(organizationId, date, email)
            .then(([{data: real}, {data: customer}]) => {
                const {ok: okCustomerOps, okOps: CustomerOps, errOps: errorCustomerOps} = verifyAppointments(customer.data);
                const {ok: okRealOps, okOps: realOps, errOps: errorRealOps} = verifyAppointments(real.data);

                // send warnings if the data is incomplete
                if (!okCustomerOps || !okRealOps) {
                    logger.warn("DayView - Customer: error appointments", {
                        organizationId,
                        email,
                        errorCustomerOps,
                        errorRealOps
                    });
                }
                const allOps = [...CustomerOps, ...realOps];

                // success action
                const status = real.ok && customer.ok ? STATUS.RESOLVED : STATUS.REJECTED;
                dispatch(loadOpDataSuccessAction(allOps, status));

                // load names
                const {practitionerIds, patientIds} = getPersonIdsFromScheduleOps(allOps);
                if (practitionerIds && practitionerIds.length) {
                    dispatch(loadNamesAction("practitioner", practitionerIds, false));
                }
                if (patientIds && patientIds.length) {
                    dispatch(loadNamesAction("patient", patientIds, false));
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch customer error"}));
                } else {
                    dispatch(loadOpDataFailureAction(error.message));
                }
            });
    };
}
/**
 * clear opData
 * @return {{type: string}}
 */
function clearOpDataAction() {
    return {type: ActionTypes.CLEAR_OP_DATA};
}

/**
 * save room filters
 * @param {Object} payload
 * @param {Array} payload.roomsFilter
 * @param {Array} payload.disciplinesFilter
 * @param {Array} payload.occupiedOpRooms
 * @param {Object} payload.disciplineRoomsMapping
 * @return {{type: string, payload: *}}
 */
function saveFiltersAction(payload) {
    return {type: ActionTypes.SAVE_FILTERS, payload};
}

/**
 * save visible disciplines
 * @param {String[]} visibleDisciplines
 * @return {{type: string, visibleDisciplines: string[]}}
 */
function saveVisibleDisciplines(visibleDisciplines) {
    return {type: ActionTypes.SAVE_VISIBLE_DISCIPLINES, visibleDisciplines};
}

const loadInfoRequestAction = () => ({
    type: ActionTypes.LOAD_INFO_REQUEST
});

const loadInfoSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_INFO_SUCCESS,
    payload
});

const loadInfoFailureAction = (error) => ({
    type: ActionTypes.LOAD_INFO_FAILURE,
    error
});

/**
 * load content for info layer except change history
 * @param {Object} args
 * @param {string} args.organizationId
 * @param {string} args.date in form of YYYY-DD-MM
 * @param {InfoLayerSettings} args.infoParams
 * @return {AnyAction}
 */
function loadInfoAction({organizationId, date, infoParams}) {
    // @ts-ignore @todo: fix this
    return function (dispatch) {
        dispatch(loadInfoRequestAction());

        fetchInfo({organizationId, date, params: infoParams})
            .then(([{data: info1}, {data: info2}, info3]) => {
                // merge all the results of info3
                const allPracRoles = flatten(info3.map((response) => response.data?.data || []));
                dispatch(
                    loadInfoSuccessAction({
                        info1: info1.data || [],
                        info2: info2.data || [],
                        info3: allPracRoles
                    })
                );
                // load names
                const practitionerIds = getPersonIdsFromInfo(concat(info1, info2, allPracRoles));
                if (practitionerIds.length) {
                    dispatch(loadNamesAction("practitioner", practitionerIds, false));
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch info error"}));
                } else {
                    dispatch(loadInfoFailureAction(error.message));
                }
            });
    };
}

const loadPrintOpDataRequestAction = () => ({
    type: ActionTypes.LOAD_PRINT_REQUEST
});

const loadPrintOpDataSuccessAction = (payload, date) => ({
    type: ActionTypes.LOAD_PRINT_SUCCESS,
    payload,
    date
});

const loadPrintOpDataFailureAction = (error) => ({
    type: ActionTypes.LOAD_PRINT_FAILURE,
    error
});

/**
 * load op data for print
 * @param {string} organizationId
 * @param {string} date
 * @return {AnyAction}
 */
function loadPrintOpDataAction(organizationId, date) {
    // @ts-ignore @todo: fix this
    return function (dispatch, getState) {
        dispatch(loadPrintOpDataRequestAction());
        const email = selectCurrentUserEmail(getState());

        fetchKi(organizationId, date, email)
            .then(([{data: real}, {data: ki}]) => {
                const {ok: okKiOps, okOps: kiOps, errOps: errorKiOps} = verifyAppointments(ki.data);
                const {ok: okRealOps, okOps: realOps, errOps: errorRealOps} = verifyAppointments(real.data);

                // send warnings
                if (!okKiOps || !okRealOps) {
                    logger.warn("DayView - Print: error appointments", {
                        organizationId,
                        email,
                        errorKiOps,
                        errorRealOps
                    });
                }
                const allOps = [...kiOps, ...realOps];

                dispatch(loadPrintOpDataSuccessAction(allOps, date));

                // load names
                const {practitionerIds, patientIds} = getPersonIdsFromScheduleOps(allOps);
                if (practitionerIds && practitionerIds.length) {
                    dispatch(loadNamesAction("practitioner", practitionerIds, false));
                }
                if (patientIds && patientIds.length) {
                    dispatch(loadNamesAction("patient", patientIds, false));
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch ki error"}));
                } else {
                    dispatch(loadPrintOpDataFailureAction(error.message));
                }
            });
    };
}

const loadPrintOnCallRequestAction = () => ({
    type: ActionTypes.LOAD_PRINT_ONCALL_REQUEST
});

const loadPrintOnCallSuccessAction = (payload, date) => ({
    type: ActionTypes.LOAD_PRINT_ONCALL_SUCCESS,
    payload,
    date
});

const loadPrintOnCallFailureAction = (error) => ({
    type: ActionTypes.LOAD_PRINT_ONCALL_FAILURE,
    error
});

/**
 * load on call information for print
 * @param {string} organizationId
 * @param {string} date in form of YYYY-DD-MM
 * @param {InfoParam} params
 * @return {AnyAction}
 */
function loadPrintOnCallAction(organizationId, date, params) {
    // @ts-ignore @todo: fix this
    return function (dispatch) {
        dispatch(loadPrintOnCallRequestAction());

        fetchPrintOnCall({organizationId, date, params})
            .then(({data}) => {
                dispatch(loadPrintOnCallSuccessAction(data?.data, date));

                // load names
                const practitionerIds = getPersonIdsFromInfo(data?.data);
                if (practitionerIds.length) {
                    dispatch(loadNamesAction("practitioner", practitionerIds, false));
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch info error"}));
                } else {
                    dispatch(loadPrintOnCallFailureAction(error.message));
                }
            });
    };
}

const loadChangeHistoryRequestAction = () => ({
    type: ActionTypes.LOAD_CHANGE_HISTORY_REQUEST
});

const loadChangeHistorySuccessAction = (payload) => ({
    type: ActionTypes.LOAD_CHANGE_HISTORY_SUCCESS,
    payload
});

const loadChangeHistoryFailureAction = (error) => ({
    type: ActionTypes.LOAD_CHANGE_HISTORY_FAILURE,
    error
});

/**
 * load change history for the info layer
 * @param {Object} args
 * @param {string} args.date today in the form of YYYY-MM-DD
 * @param {Array<ChangeLogOp>} args.changeLogOps all ids for today from the real and ki/published routes
 * @return {AnyAction}
 */
function loadChangeHistoryAction({date, changeLogOps}) {
    // @ts-ignore @todo: fix this
    return function (dispatch, getState) {
        dispatch(loadChangeHistoryRequestAction());
        const {PARTICIPANT, LOCATION, START, CANCELLED, REVOKED, PLANNED, MAX_NUMBER_OF_IDS} = RESOURCE_HISTORY;

        // Split into chunkIds
        const chunkIds = sliceIntoChunks(
            changeLogOps.map((op) => op.id),
            MAX_NUMBER_OF_IDS
        );

        // Prepare params
        const params = {PARTICIPANT, LOCATION, START, PLANNED, CANCELLED, REVOKED};

        fetchChangeHistoryForToday({date, chunkIds, params})
            .then(([practitionerChanges, locationChanges, startChanges, bookedChanges, {data: cancelChanges}, {data: revokeChanges}]) => {
                const {publishedAt} = selectLastPublishedSession(getState());

                // Format results
                // practitionerChanges, locationChanges and startChanges are already filtered by the start === today
                const flattenedChanges = [];
                [...practitionerChanges, ...locationChanges, ...startChanges, ...bookedChanges].forEach(({data}) => {
                    if (data?.changes?.length) {
                        flattenedChanges.push(...data.changes);
                    }
                });
                // filter and format changes
                const result = flattenedChanges.filter(filterChanges(changeLogOps, date, publishedAt)).map(formatChanges(changeLogOps));

                // Prepare to fetch the service requests that were revoked or cancelled
                let ids = [];
                cancelChanges.changes?.forEach((change) => ids.push(change.resourceUri.split("/")[1]));
                revokeChanges.changes?.forEach((change) => ids.push(change.resourceUri.split("/")[1]));

                // unique ids
                ids = [...new Set(ids)];
                // Split into chunkIds
                const resourceChunkIds = sliceIntoChunks(ids, MAX_NUMBER_OF_IDS);

                // changelChanges and revokeChanges are all changes done today, so they need to be filtered by originally planned date === today
                fetchResources({resourceChunkIds}).then(([resourceAppointmentResponses, resourceServiceRequestResponses]) => {
                    const resourceAppointmentList = resourceAppointmentResponses
                        .filter(({data}) => Boolean(data))
                        .reduce((acc, {data}) => [...acc, ...data], []);

                    const resourceServiceRequestList = resourceServiceRequestResponses
                        .filter(({data}) => Boolean(data))
                        .reduce((acc, {data}) => [...acc, ...data], []);

                    // emergency threshold
                    const {emergencyThreshold} = selectFeSettings(getState());

                    // format change log ops
                    /** @type {Array<ChangeLogOp>} */
                    const statusChangedOps = [];
                    for (const id of ids) {
                        /** @type {ChangeLogOp} */
                        const changeLogOp = {id, patientId: null, start: null, isEmergency: false};
                        const resourceAppointment = resourceAppointmentList.find((el) => el.resourceId === id);
                        if (resourceAppointment) {
                            changeLogOp.start = resourceAppointment?.current?.start;
                        }
                        const resourceServiceRequest = resourceServiceRequestList.find((el) => el.resourceId === id);
                        if (resourceServiceRequest) {
                            const priority = resourceServiceRequest?.current?.next_priority?.text
                                ? parseInt(resourceServiceRequest.current.next_priority.text)
                                : 0;
                            changeLogOp.isEmergency = isEmergencyFunc(priority, emergencyThreshold);
                            changeLogOp.patientId = resourceServiceRequest?.current?.subject;
                        }
                        statusChangedOps.push(changeLogOp);
                    }
                    if (cancelChanges.changes?.length || revokeChanges.changes?.length) {
                        result.push(
                            ...[...(cancelChanges.changes || []), ...(revokeChanges.changes || [])]
                                .filter(filterChanges(statusChangedOps, date, publishedAt))
                                .map(formatChanges(statusChangedOps))
                        );
                    }

                    dispatch(loadChangeHistorySuccessAction(result));

                    // load names
                    const practitionerIds = [];
                    practitionerChanges.forEach(({data}) => {
                        if (data?.changes?.length) {
                            data.changes.forEach((change) => {
                                practitionerIds.push(change.value, change.valueBefore);
                            });
                        }
                    });
                    if (practitionerIds.length) {
                        dispatch(loadNamesAction("practitioner", [...new Set(practitionerIds)], false));
                    }

                    const patientIds = result.filter((change) => change.patientId).map((change) => change.patientId);
                    if (patientIds.length) {
                        dispatch(loadNamesAction("patient", patientIds, false));
                    }
                });
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch info error"}));
                } else {
                    dispatch(loadChangeHistoryFailureAction(error.message));
                }
            });
    };
}

/**
 * Reset OP DATA status
 * @return {{type: string}}
 */
function resetOpDataStatusAction() {
    return {type: ActionTypes.RESET_OP_DATA_STATUS};
}

/**
 * set hovered op
 *
 * @param {string} opId
 * @return {{type: string, opId: string}}
 */
function setHoveredOp(opId) {
    return {type: ActionTypes.SET_HOVERED_OP, opId};
}

/**
 * set clicked op
 *
 * @param {string} opId
 * @return {{type: string, opId: string}}
 */
function setClickedOp(opId) {
    return {type: ActionTypes.SET_CLICKED_OP, opId};
}

export {
    changeDate,
    saveOpRooms,
    loadOpDataCustomerAction,
    loadOpDataKiAction,
    clearOpDataAction,
    saveFiltersAction,
    saveVisibleDisciplines,
    loadInfoAction,
    loadPrintOpDataAction,
    loadPrintOnCallAction,
    loadChangeHistoryAction,
    resetOpDataStatusAction,
    setHoveredOp,
    setClickedOp
};
