// @ts-check
import {loadNamesAction} from "../../components/private_data/private_data_actions";
import {authUserFailureAction} from "../../redux/actions/index";
import ActionTypes from "./admin_user_action_types";
import {fetchRolesAPI, fetchUsersAPI, saveUserAPI} from "./admin_user_api";

const loadUsersRequestAction = () => ({
    type: ActionTypes.LOAD_REQUEST
});

const loadUsersSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_SUCCESS,
    payload
});

const loadUsersFailureAction = () => ({
    type: ActionTypes.LOAD_FAILURE
});

/**
 * load users
 * @param {Object} params
 * @param {String} organizationId
 * @return {function}
 */
function loadUsersAction(params, organizationId) {
    return function (dispatch) {
        dispatch(loadUsersRequestAction());

        fetchUsersAPI(params, organizationId)
            .then(({data}) => {
                dispatch(loadUsersSuccessAction(data.data));

                // load names
                dispatch(loadNamesAction("practitioner", data.data?.map((el) => el.practitionerId) || [], false));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch users error"}));
                } else {
                    dispatch(loadUsersFailureAction());
                }
            });
    };
}
const saveUserRequestAction = () => ({
    type: ActionTypes.SAVE_REQUEST
});

const saveUserSuccessAction = (payload) => ({
    type: ActionTypes.SAVE_SUCCESS,
    payload
});

const saveUserFailureAction = (saveError) => ({
    type: ActionTypes.SAVE_FAILURE,
    saveError
});

/**
 * save user
 * @param {Object} params
 * @param {"add"|"modify"} type
 * @return {function}
 */
function saveUserAction(params, type) {
    return function (dispatch) {
        dispatch(saveUserRequestAction());

        saveUserAPI(params, type)
            .then(() => {
                dispatch(saveUserSuccessAction());
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "save user error"}));
                } else {
                    dispatch(saveUserFailureAction(error.response.data?.data || []));
                }
            });
    };
}

const loadRolesSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_ROLES,
    payload
});

/**
 * load roles
 * @return {function}
 */
function loadRolesAction() {
    return function (dispatch) {
        fetchRolesAPI()
            .then(({data}) => {
                dispatch(loadRolesSuccessAction(data.roles));
            })
            .catch((e) => {
                if (e.response && e.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch roles error"}));
                }
            });
    };
}

export {loadUsersAction, saveUserAction, loadRolesAction};
